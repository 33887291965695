import React from "react";

export default function Slide(props) {
    let active = "";
    if(props.active && props.active === true) {
        active = " active";
    }
    return (
        <div className={"slide"+active}>
            {props.content}
        </div>
    );
}