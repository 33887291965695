import React from 'react';
import './SlideshowPanel.scss';
import Panel from '../Panel/Panel';
import Slide from './Slide';


export default class SlideshowPanel extends Panel {
    constructor(props) {
        super(props);
        this.timer = null;
        this.slides = this.initiateSlides();
        this.state = {
            activeSlide: 0,
        }
    }

    initiateSlides() {
        return [];
    }

    componentDidMount() {
        this.go();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    go(beat = this.props.beat) {
        clearInterval(this.timer);
        this.timer = setInterval(
          () => this.tick(),
          beat,
        );
    }

    tick() {
        this.moveSlide(1);
        this.go();
    }

    pause() {
        clearInterval(this.timer);
    }

    moveSlide(delta) {
        let new_slide = this.state.activeSlide + delta;
        if(new_slide < 0) {
            new_slide = this.slides.length-1;
        } else if (new_slide >= this.slides.length) {
            new_slide = 0;
        }
        this.setState({activeSlide: new_slide});
    }

    getContent() {
        let final_slides = [];
        for(let [index, one_slide] of this.slides.entries()) {
            final_slides.push(
                <Slide active={index === this.state.activeSlide} content={one_slide} key={index} />
            );
        }

        return (
            <div
                className="slideshow-container"
                onMouseEnter={()=>this.pause()}
                onMouseLeave={()=>this.go(this.props.unpause_beat)}
            >
                <button
                    className="arrow arrow-left"
                    onClick={()=>this.moveSlide(-1)}
                >
                    Previous
                </button>
                <button
                    className="arrow arrow-right"
                    onClick={()=>this.moveSlide(1)}
                >
                    Next
                </button>
                {final_slides}
            </div>
        );
    }
}