import React from 'react';
import './AboutPanel.scss';
import SlideshowPanel from '../SlideshowPanel/SlideshowPanel';

export default class AboutPanel extends SlideshowPanel {
    initiateSlides() {
        let basics = (
            <div className="slide-basics slide-all">
                <div className="slide-half">
                    <img src="images/box3d.png" alt="Forgotten Waters box" className="about-box" />
                    <img className="about-players" alt="For 3-7 Players" src="images/players.png" />
                </div>
                <div className="slide-half">
                    <h3>Piratey Misadventure in&nbsp;a&nbsp;World&nbsp;o'&nbsp;Magic!</h3>
                    <hr />
                    <ul>
                        <li>
                            <img src="images/x.png" alt="X" className="x-marks" />
                            <span>Uncover a massive world full of silly adventure you will return to over and over&nbsp;again.</span>
                        </li>
                        <li>
                            <img src="images/x.png" alt="X" className="x-marks" />
                            <span>Explore standalone scenarios, each helmed by a larger-than-life pirate captain, whose
                                ambitions may undo you&nbsp;all.</span>
                        </li>
                        <li>
                            <img src="images/x.png" alt="X" className="x-marks" />
                            <span>Face harrowing crossroad events that will shape the course of your&nbsp;game.</span>
                        </li>
                    </ul>
                </div>
            </div>
        );
        let locations = (
            <div className="slide-locations slide-all">
                <div className="info-container">
                    <h3>Over 30 Lush Locations&nbsp;to&nbsp;Explore!</h3>
                    <hr />
                    <div className="description">
                        Traverse through a wealth of intriguing locales, each with unique, scenario-specific content.
                    </div>
                </div>
                <img src="images/tortalo.png" alt="Location Book" className="location-book" />
            </div>
        );
        let app = (
            <div className="slide-app slide-all">
                <div className="slide-three-quarters">
                    <h3>Hear Your Adventures Come to&nbsp;Life&nbsp;Like&nbsp;Never&nbsp;Before!</h3>
                    <hr />
                    <ul>
                        <li>
                            <img src="images/x.png" alt="X" className="x-marks" />
                            <span>Interact with and reshape your world through a fully-narrated online app.</span>
                        </li>
                        <li>
                            <img src="images/x.png" alt="X" className="x-marks" />
                            <span>Hundreds of crossroad moments!</span>
                        </li>
                        <li>
                            <img src="images/x.png" alt="X" className="x-marks" />
                            <span>Use in any web browser or save on your phone for offline use.</span>
                        </li>
                        <li>
                            <img src="images/x.png" alt="X" className="x-marks" />
                            <span>Thrill as your failures are played out by professional actors!</span>
                        </li>
                    </ul>
                </div>
                <div className="slide-one-quarter">
                    <img src="images/app.png" alt="Forgotten Waters App" className="app-phone" />
                </div>
            </div>
        );
        let sheets = (
            <div className="slide-sheets slide-all">
                <div className="sheets-content-container">
                    <div className="sheets-content-container-content">
                        <h3>Be the Pirate Yer Mother Never&nbsp;Wanted&nbsp;Ya&nbsp;To&nbsp;Be!</h3>
                        <hr />
                        <ul>
                            <li>
                                <img src="images/x.png" alt="X" className="x-marks" />
                                <span>Create your own pirate, complete with a one-of-a-kind backstory.</span>
                            </li>
                            <li>
                                <img src="images/x.png" alt="X" className="x-marks" />
                                <span>Your actions will determine if their life ends in glorious triumph or ignoble disgrace.</span>
                            </li>
                        </ul>
                    </div>
                    <img src="images/standees.png" alt="pirate standees" className="sheets-standees" />
                </div>
                <img src="images/sheets_l.png" alt="player sheets" className="sheets-sheets" />
            </div>
        );
        return [
            basics,
            sheets,
            locations,
            app,
        ]
    }
}
