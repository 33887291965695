import React from 'react';

export default class Captain extends React.Component {
    render() {
        let circle_container = null;
        let quote_container = null;
        if(this.props.quote && this.props.quote!=="") {
            circle_container = (
                <svg className="shape-container" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <circle
                        cx="49.5%"
                        cy="50%"
                        r="38.5%"
                    >
                    </circle>
                </svg>
            );
            quote_container = (
                <div className="quote">
                    <i>{this.props.quote}</i>
                </div>
            );
        }
        return (
            <div className="captain">
                <img
                    src={this.props.src}
                    alt={this.props.name}
                    className="captain"
                />
                {circle_container}
                {quote_container}
                <div className="name">
                    {this.props.name}
                </div>
            </div>
        );
    }
}