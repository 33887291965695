import React from 'react';
import './Panel.scss';

export default class Panel extends React.Component {
    getRopesHtml() {
        let ropes = null;
        if(!this.props.is_last || this.props.is_last === false) {
            ropes = (<div className="preorder-site-panel-connector"></div>);
        }
        return ropes;
    }

    getContent() {
        return this.props.content;
    }


    render() {
        return(
            <div className="preorder-site-panel-container">
                 <div className="preorder-site-panel">
                     <div className="a-hold" id={this.props.id} key={"panel_"+this.props.id}></div>
                     {this.getContent()}
                 </div>
                {this.getRopesHtml()}
            </div>
        );
    }
}