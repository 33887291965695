import React from 'react';
import './Menu.scss';

export default class Menu extends React.Component {
    render() {
        let items = [];
        for(let one_panel of this.props.panels) {
            if(!one_panel.props.hidden_from_menu || one_panel.props.hidden_from_menu !== true) {
                items.push(
                    <li key={one_panel.props.id}>
                        <button
                            onClick={()=>this.props.onMenuSelect(one_panel.props.id)}
                            className={"menu-item menu-item-"+one_panel.props.id}
                        >
                            {one_panel.props.title}
                        </button>
                    </li>
                );
            }

        }

        return(
            <ul>
                <li key="logo">
                    <a href="https://www.plaidhatgames.com" target="_new" onClick={()=>this.props.onMenuSelect(null)}>
                        <img src="phg_white.png" className="phg-white" alt="Plaid Hat Games" />
                    </a>
                </li>
                {items}
            </ul>
        );
    }
}
