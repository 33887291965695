import React from 'react';
import './TrailerPanel.scss';
import Panel from '../Panel/Panel';

export default class TrailerPanel extends Panel {

    getContent() {
        return (
            <div className="trailer-container">
                <iframe
                    title="Forgotten Waters Trailer"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    type="text/html"
                    src={"https://www.youtube.com/embed/"+this.props.code+"?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0"}
                >
                </iframe>
            </div>
        );
    }
}