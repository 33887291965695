import React from 'react';
import './CaptainsPanel.scss';
import Panel from '../Panel/Panel';
import Captain from './Captain';

export default class CaptainsPanel extends Panel {

    getContent() {
        return (
            <div className="captains-container also-design-team">
                <div className="title-container">
                    <h3>
                        Design Team
                    </h3>
                    <hr />
                </div>

                <div className="only-row">
                    <div className="person-container">
                        <Captain
                        src="images/d_joe.png"
                        name="J. Arthur Ellis"
                        />
                        <div className="person-container-title">
                            Designer/App&nbsp;Developer/Writer
                        </div>
                        <div className="person-container-credits">
                            <strong>Piratey Inspirations</strong>
                            <br /><em>The&nbsp;Secret&nbsp;of Monkey&nbsp;Island, On&nbsp;Stranger&nbsp;Tides,&nbsp;Rum</em>
                        </div>
                        <div className="person-container-credits">
                            <strong>Credits Include</strong>
                            <br /><em>Raxxon, Crystal&nbsp;Clans, Dead&nbsp;of&nbsp;Winter expansions</em>
                        </div>
                    </div>
                    <div className="person-container">
                        <Captain
                            src="images/d_isaac.png"
                            name="Isaac Vega"
                        />
                        <div className="person-container-title">
                            Designer/Writer
                        </div>
                        <div className="person-container-credits">
                            <strong>Piratey Inspirations</strong>
                            <br /><em>Pirates&nbsp;of&nbsp;the&nbsp;Caribbean: The&nbsp;Curse&nbsp;of&nbsp;the&nbsp;Black&nbsp;Pearl, Muppet&nbsp;Treasure&nbsp;Island</em>
                        </div>
                        <div className="person-container-credits">
                            <strong>Credits Include</strong>
                            <br /><em>Dead&nbsp;of&nbsp;Winter, Ashes:&nbsp;Rise&nbsp;of the&nbsp;Phoenixborn, Starship&nbsp;Samurai</em>
                        </div>
                    </div>
                    <div className="person-container">
                        <Captain
                            src="images/d_bistro.png"
                            name="Mr. Bistro"
                        />
                        <div className="person-container-title">
                            Writer/Designer
                        </div>
                        <div className="person-container-credits">
                            <strong>Piratey Inspirations</strong>
                            <br /><em>The&nbsp;Marvelous Misadventures of&nbsp;Flapjack, Treasure&nbsp;Island, Black&nbsp;Flag&nbsp;(band)</em>
                        </div>
                        <div className="person-container-credits">
                            <strong>Credits Include</strong>
                            <br /><em>Dungeon&nbsp;Run, Mice&nbsp;and&nbsp;Mystics, Stuffed&nbsp;Fables, Aftermath</em>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
