import React from 'react';
import './CaptainsPanel.scss';
import Panel from '../Panel/Panel';
import Captain from './Captain';

export default class CaptainsPanel extends Panel {

    getContent() {
        return (
            <div className="captains-container">
                <div className="title-container">
                    <h3>
                        Meet Yer Captains!
                    </h3>
                    <hr />
                </div>
                <div className="first-row">
                    <Captain
                    src="images/c_vance.png"
                    quote="&quot;There was magic in this world once, me matey. Ya can feel its absence deep in yer&nbsp;bones.&quot;"
                    name="Captain Jesbut J. Vance"
                    />
                    <Captain
                        src="images/c_swift.png"
                        quote="&quot;When I find Lees' island, I find my people, and then I'll make that ghost captain scream in&nbsp;anguish.&quot;"
                        name="Captain Raeni Swift"
                    />
                    <Captain
                        src="images/c_calhoun.png"
                        quote="&quot;My name's Bonnie Calhoun, and not Emma Johnson, the turncoat wanted by the Grand Pirate Council. Got&nbsp;it?&quot;"
                        name="Captain Bonnie Calhoun"
                    />
                </div>
                <div className="second-row">
                    <Captain
                        src="images/c_okoro.png"
                        quote="&quot;I hear tell that a witch's heart can grant wishes, and I have a mind to find&nbsp;out.&quot;"
                        name="Captain Benjamin Okoro"
                    />
                    <Captain
                        src="images/c_stroop.png"
                        quote="&quot;When we capture beasts from lands unknown, we add to the knowledge of all humans. And get rich doin'&nbsp;it!&quot;"
                        name="Captain Claudia Stroopwafel"
                    />
                </div>
            </div>
        );
    }
}
